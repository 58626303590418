// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acomodacoes-bangalo-do-soter-js": () => import("./../../../src/pages/acomodacoes/bangalo-do-soter.js" /* webpackChunkName: "component---src-pages-acomodacoes-bangalo-do-soter-js" */),
  "component---src-pages-acomodacoes-casa-do-soter-js": () => import("./../../../src/pages/acomodacoes/casa-do-soter.js" /* webpackChunkName: "component---src-pages-acomodacoes-casa-do-soter-js" */),
  "component---src-pages-acomodacoes-index-js": () => import("./../../../src/pages/acomodacoes/index.js" /* webpackChunkName: "component---src-pages-acomodacoes-index-js" */),
  "component---src-pages-disponibilidade-e-valores-js": () => import("./../../../src/pages/disponibilidade-e-valores.js" /* webpackChunkName: "component---src-pages-disponibilidade-e-valores-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

